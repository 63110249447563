.promotions {
    padding: 0 10%;

    .card.not-active {
        filter: grayscale(1);
    }

    .card{
        height: 100%;
        max-height: 480px;
        padding: 0;
        background-color: #fff;
        border-radius: 10px;
        border: 3px solid #EEEEEE;
        //border:none;
        //width: 190px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        .btn-inner-promotion-timer {
            width: 100%;
            border: 1px solid #db158c;
            color: #db158c;
        }

        .btn-inner-promotion-timer:hover {
            width: 100%;
            border: 1px solid #ac116e;
            background-color: #c4137d;
            color: white;
        }
    }

    .card:hover {
        border-color: #db158c;
    }

    .attributes-container {
        .title {
            font-size:11px;color:#999999
        }

        .value {
            font-size:12px;font-weight:bold;color:#AB4B71
        }
    }

    .image-container{
        padding: 1%;
        position: relative;

        .image-overlay-text {
            position: absolute;
            background: rgb(237 237 237 / 75%);
            width: 100%;
            padding: 3% 5%;
            bottom: 0;
            left: 0;

            .big-overlay-text {
                font-family:  Arial, Helvetica, sans-serif;
                font-size: 30px;
                line-height: 25px;
            }

            .regular-text {
                font-weight: normal;
            }
            .bold-text {
                font-weight: bold;
            }

            .price-text {
                color:#db158c;
            }
        }
    }

    .thumbnail-image{
        border-radius: 10px !important;
        max-height: 300px;
    }

    .discount{
        background-color: red;
        padding-top: 1px;
        padding-bottom: 1px;
        padding-left: 4px;
        padding-right: 4px;
        font-size: 10px;
        border-radius: 6px;
        color: #fff;
    }

    .store{
        height: 40px;
        width: 40px;
        background-color: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .first{
        position: absolute;
        width: 100%;
        padding: 9px;
    }


    .dress-name{
        font-size: 13px;
        font-weight: bold;
        width: 75%;
    }


    .new-price{
        font-size: 13px;
        font-weight: bold;
        color: red;

    }
    .old-price{
        font-size: 8px;
        font-weight: bold;
        color: grey;
    }

    //
    //.btn{
    //    width: 14px;
    //    height: 14px;
    //    border-radius: 50%;
    //    padding: 3px;
    //}

    .creme{
        background-color: #fff;
        border: 2px solid grey;


    }
    .creme:hover {
        border: 3px solid grey;
    }

    .creme:focus {
        background-color: grey;

    }


    .red{
        background-color: #fff;
        border: 2px solid red;
    }

    .red:hover {
        border: 3px solid red;
    }
    .red:focus {
        background-color: red;
    }

    .blue{
        background-color: #fff;
        border: 2px solid #40C4FF;
    }

    .blue:hover {
        border: 3px solid #40C4FF;
    }
    .blue:focus {
        background-color: #40C4FF;
    }
    .darkblue{
        background-color: #fff;
        border: 2px solid #01579B;
    }
    .darkblue:hover {
        border: 3px solid #01579B;
    }
    .darkblue:focus {
        background-color: #01579B;
    }
    .yellow{
        background-color: #fff;
        border: 2px solid #FFCA28;
    }
    .yellow:hover {
        border-radius: 3px solid #FFCA28;
    }
    .yellow:focus {
        background-color: #FFCA28;
    }

    .item-size{
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid grey;
        color: grey;
        font-size: 10px;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .rating-star{
        font-size: 10px !important;
    }
    .rating-number{
        font-size: 10px;
        color: grey;
    }
    .buy{
        font-size: 12px;
        color: purple;
        font-weight: 500;
    }


    @media screen and (max-width: 480px) {
        .card {
            margin-bottom: 20px;
        }
    }

}

@media screen and (max-width: 480px) {
    .promotions {
        padding: 0;
        margin-left: 2px;
    }
}

.navbar-category {
    .navbar-toggler {
        margin-left: auto;
    }
}

.btn-togetho {
     border: 1px solid;
     color: white;
     background-color: #ba5390;
     transition: background-color 0.3s ease;
 }

.btn-togetho:hover,
.btn-togetho:focus,
.btn-togetho:active {
    background-color: #c9599b;
    color: white;
    outline: none;
    box-shadow: none;
}

.btn-purple {
    background-color: #ba5390;
    --bs-btn-color: #fff;
    --bs-btn-bg: #ba5390;
    --bs-btn-border-color: #ba5390;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #a84981;
    --bs-btn-hover-border-color: #a94580;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #953c70;
    --bs-btn-active-border-color: #893768;
    --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #f1c1dd;
    --bs-btn-disabled-border-color: #dbaec9;
}