// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;


$link-color: #a84981;
$component-active-color: #ffffff;
$component-disabled-color: #eadee5;
$component-active-bg: #a84981;
$white: #ffffff;
$gray-300: #dee2e6;

$pagination-color: $link-color !default;
$pagination-bg: $white !default;
$pagination-border-color: $gray-300 !default;
//
//$pagination-hover-color: $link-hover-color !default;
//$pagination-hover-bg: $gray-200 !default;
//$pagination-hover-border-color: $gray-300 !default;
//
$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;

$pagination-disabled-color: $component-disabled-color !default;
//$pagination-disabled-bg: $white !default;
//$pagination-disabled-border-color: $gray-300 !default;

// Buttons
$pagination-disabled-color: $component-disabled-color !default;

